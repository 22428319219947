.l-online-form {
  max-width: 870px;
  .l-item {
    width: 100%;
  }
  textarea {
    background: #ffffff;
    border: 1px solid #dadada;
    border-radius: 10px;
    resize: vertical;
    padding: 15px;
  }
  label.input-file {
    max-width: 390px;
  }
}

.l-input-items {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
  .l-item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    @include xxs {
      gap: 5px;
    }
  }
}

.l-online-content {
  p {
    font-weight: 300;
  }
}

.bold {
  font-weight: 700;
}

.l-df-items {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  @include xm {
    gap: 20px;
  }
  @include m-small {
    flex-direction: column;
    gap: 15px;
  }
}
