.l-news-2-content {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  @include xxm {
    flex-direction: column;
  }
}

.l-news-today {
  max-width: 860px;
  // min-width: 420px;
  height: 400px;
  display: flex;
  flex-direction: column;
  @include xxm {
    height: auto;
    justify-content: space-between;
    order: 2;
  }
  span {
    display: block;
    margin-bottom: 20px;
    @include xxs {
      margin-bottom: 10px;
    }
  }
  p {
    @include xl {
      display: -webkit-box;
      -webkit-line-clamp: 7;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .c-blue-btn {
    margin-top: 30px;
    align-self: flex-start;
    padding: 12px 24px;
  }
}

.swiper-news {
  max-width: 420px;
  min-width: 420px;
  overflow-x: hidden;
  position: relative;
  height: 400px;
  @include xxm {
    max-width: 100%;
    min-width: 288px;
    height: auto;
  }
  .swiper-slide {
    @include xxm {
      height: 200px;
    }
    span {
      margin-bottom: 20px;
      display: block;
      @include xxs {
        margin-bottom: 10px;
      }
    }
  }
  .swiper-btn__block {
    z-index: 111;
    position: absolute;
    right: 37px;
    bottom: 37px;
    display: flex;
    gap: 20px;
    cursor: pointer;
    @include xxs {
      right: 17px;
      bottom: 17px;
    }
  }
}
