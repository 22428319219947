$transition: cubic-bezier(0.075, 0.82, 0.165, 1);

@import "block/components-style/_reset.scss";

*,
*::before,
*::after {
  text-decoration: unset;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

ul,
ol,
li {
  list-style: none;
}

@import "media-mixin.scss";

html {
  scroll-behavior: smooth;
  height: 100%;
  overflow-x: hidden;
  position: relative;
  min-width: 320px;
}

body {
  position: relative;
  font-family: "Ubuntu", sans-serif;
  height: 100%;
  overflow-x: hidden;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.l-wrapper {
  position: relative;
  max-width: 1440px;
  width: 100%;
  padding: 0 70px;
  height: auto;
  margin: 0 auto;
  @include xl {
    padding: 0 55px;
    @include xm {
      padding: 0 40px;
      @include m-640 {
        padding: 0 18px;
      }
    }
  }
  @include xxs {
    padding: 0 16px;
  }
}

.m-order {
  display: flex;
  flex-direction: column;
  .l-services {
    @include m-small {
      order: 3;
    }
  }
  .l-lk-btn {
    display: none;
    order: 2;
    padding: 0 18px;
    margin: 10px 0 30px 0;
    @include m-small {
      display: block;
      a {
        justify-content: center;
      }
    }
  }
}

.l-peredacha {
  .static-form {
    max-width: 870px;
  }
}

.static-form-700 {
  max-width: 700px;
}

.l-lk-block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: 100%;
  height: 100%;
  @include xxs-big {
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .l-item {
    display: flex;
    gap: 20px;
    align-items: center;
    background: #ffffff;
    border: 1px solid #dadada;
    border-radius: 5px;
    padding: 50px;
    @include m-900 {
      padding: 15px;
    }
    div {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;
      span {
        width: 100%;
        text-align: start;
      }
    }
  }
}

@import "block/components-style/root.scss";
@import "block/components-style/hystmodal.scss";
@import "block/components-style/custom-select.scss";

@import "./block/components-style/_icons.scss";

@import "block/components-style/header.scss";
@import "block/components-style/footer.scss";
@import "block/components-style/_components.scss";
@import "block/components-style/airdatepicker.scss";

@import "block/l-main/l-banner.scss";
@import "block/l-main/l-services.scss";
@import "block/l-main/l-news.scss";
@import "block/l-main/l-news-2.scss";

@import "block/l-history/l-history.scss";
@import "block/l-history/l-leaderships.scss";

@import "block/l-news/l-news.scss";
@import "block/l-vacancies/l-vacancies.scss";
@import "block/p-info.scss";
@import "block/p-online.scss";
@import "block/p-contacts.scss";
