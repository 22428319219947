.p-vacancies {
  .search-form {
    display: block;
    .js-select {
      width: 200px;
      @include m-small {
        width: 100%;
      }
    }
    .l-form-items {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 30px;
      @include m-950 {
        flex-direction: column;
        flex-wrap: unset;
        gap: 15px;
        align-items: flex-start;
      }
      .l-item {
        display: flex;
        gap: 20px;
        align-items: center;
        @include m-950 {
          flex-direction: column;
          gap: 10px;
          align-items: flex-start;
          width: 100%;
          .datepick {
            width: 100%;
          }
        }
        .datepick {
          width: 200px;
        }
      }
    }
  }
}

.l-vacancies-item__grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  @include m-small {
    gap: 20px;
  }
}

.l-vacancies-item {
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 20px;
  padding: 40px 50px;
  position: relative;
  @include l {
    padding: 30px;
  }
  @include m-small {
    padding: 15px;
  }
  .l-title {
    display: flex;
    flex-direction: column;
    gap: 15px;
    @include xxs {
      gap: 10px;
    }
    h5 {
      font-weight: 500;
      font-size: 20px;
      line-height: 140%;
      color: #232323;
      @include xm {
        font-size: 18px;
      }
      @include m-small {
        font-size: 16px;
      }
      @include xxs {
        font-size: 14px;
      }
    }
  }
  .l-show-item {
    margin-top: 57px;
    @include l {
      margin-top: 50px;
    }
    @include m-670 {
      margin-top: 30px;
    }
    @include m-small {
      margin-top: 15px;
    }
  }
}

.l-response {
  position: absolute;
  right: 50px;
  bottom: 40px;

  width: 166px;
  background: #005999;
  border-radius: 10px;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  text-align: center;
  padding: 12px 0;
  cursor: pointer;

  @include l {
    right: 30px;
    bottom: 30px;
  }
  @include m-670 {
    display: none;
  }
}

.m-response {
  cursor: pointer;
  background: #005999;
  border-radius: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
  text-align: center;
  padding: 12px 0;
  width: 100%;
  display: none;
  @include m-670 {
    display: block;
    margin-top: 15px;
  }
}

.l-will-show {
  max-height: 0;
  opacity: 0;
  padding: 0;
  transition: opacity 0.4s cubic-bezier(0.075, 0.82, 0.165, 1),
    max-height 0.2s linear, padding 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  &.active {
    // transition: opacity .4s linear, max-height .9s linear;
    max-height: 100%;
    opacity: 1;
    padding: 20px 0 80px 0;
    .l-vacancies-dropdown__content {
      display: block;
    }
    @include m-670 {
      padding: 20px 0;
    }
  }
  .l-vacancies-dropdown__content {
    display: none;
    p {
      font-weight: 300;
    }
    li.l-item {
      padding: 20px 0;
      display: grid;
      grid-template-columns: 380px 1fr;
      gap: 20px;
      border-top: 1px solid #dadada;
      @include l {
        grid-template-columns: 300px 1fr;
      }
      @include m-950 {
        grid-template-columns: 200px 1fr;
      }
      @include m-670 {
        grid-template-columns: 1fr;
      }
      &:last-child {
        border-bottom: 1px solid #dadada;
      }
      span {
        font-weight: 500;
      }
    }
  }
}

ul.trebovania {
  margin: 0;
  li {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: unset;
    }
  }
}

.l-popup-form {
  .title {
    text-align: center;
    margin-bottom: 35px;
    h5 {
      font-weight: 500;
      font-size: 35px;
      line-height: 40px;
      color: #005999;
    }
  }
  textarea {
    background: #ffffff;
    border: 1px solid #dadada;
    border-radius: 10px;
    resize: vertical;
    padding: 10px;
  }
}
.l-popup-inputs {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-bottom: 20px;
  @include m-small {
    gap: 15px;
  }
  .c-df-20 {
    justify-content: space-between;
  }
  .l-item {
    display: flex;
    flex-direction: column;
    gap: 10px;

    span {
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      color: #232323;
      @include l {
        font-size: 14px;
        line-height: 16px;
      }
      @include xxxs {
        font-size: 12px;
        line-height: 14px;
      }
    }
    input {
      background: #ffffff;
      border: 1px solid #dadada;
      border-radius: 10px;
      width: 100%;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      color: #232323;
      height: 45px;
      padding: 0 15px;
      @include l {
        font-size: 14px;
        line-height: 16px;
      }
      @include xxs {
        height: 41px;
      }
      @include xxxs {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
}

.l-detail-open {
  cursor: pointer;
}

.input-file {
  position: relative;
  i.arrow__next {
    position: absolute;
    right: 10px;
    top: 7px;
  }
  input {
    display: none;
  }
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #9da7bb;

    background: #ffffff;
    border: 1px solid #dadada;
    border-radius: 10px;
    height: 45px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
    @include xxs {
      font-size: 12px;
    }
  }
}

.l-confidensial {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;
  span {
    width: 80%;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #232323;
    @include m-small {
      font-size: 14px;
    }
    @include xxxs {
      font-size: 12px;
      line-height: 140%;
    }
  }
  a {
    text-decoration: underline;
  }
  input {
    background: #ffffff;
    border: 1px solid #005999;
    border-radius: 5px;
    margin: 0;
    padding: 4px;
    width: 24px;
    height: 24px;
    &:checked {
      &::after {
        content: "";
        width: 14px;
        height: 14px;
        background-color: #005999;
        display: block;
        border-radius: 2px;
      }
    }
  }
}

.l-send-btn {
  button {
    cursor: pointer;
    background: #005999;
    border-radius: 10px;
    text-align: center;
    padding: 12px 23px;
    @include m-small {
      width: 100%;
    }
  }
}
