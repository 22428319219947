section.l-services {
  z-index: 111;
}
div.l-services {
  margin-top: -65px;
  overflow: hidden;
  @include m-small {
    margin-top: unset;
  }
}

.c-grid-main {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  border-radius: 28px;
  background: white;
  border: 1px solid #dadada;
  overflow: hidden;
  @include xl {
    grid-template-columns: 50% 50%;
  }
  .b-item {
    margin: 0px 0 0 -1px;
    box-shadow: 1px 1px 0 0 #dadada;
    display: flex;
    align-items: center;
    @include m-small {
      justify-content: center;
    }
    a {
      padding: 37px 30px;
      display: flex;
      align-items: flex-start;
      align-items: center;
      gap: 10px;
      transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
      @include xl {
        justify-content: center;
      }
      @include m-small {
        flex-direction: column;
        align-items: center;
        padding: 20px;
        text-align: center;
        height: 100%;
      }
      @include xxxs {
        padding: 15px;
      }
      img {
        width: 56px ;
        height: 56px;
        @include xxs {
          width: 32px;
          height: 32px;
        }
      }
      // &:hover {
      //   background-color: #005999;
      //   span {
      //     color: white;
      //   }
      // }
    }
  }
}
