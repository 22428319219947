.c-img-block {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

.section {
  padding: 40px 0;
  @include m-small {
    padding: 20px 0;
  }
}

.c-pb-40 {
  padding-bottom: 40px;
  @include m-small {
    padding-bottom: 20px;
  }
}
.c-pb-30 {
  padding-bottom: 30px;
  @include m-small {
    padding-bottom: 20px;
  }
}
.c-pb-20 {
  padding-bottom: 20px;
  @include m-small {
    padding-bottom: 15px;
  }
}
.c-pb-10 {
  padding-bottom: 10px;
}

.title {
  h1 {
    font-weight: 500;
    font-size: 35px;
    line-height: 40px;
    color: #ffffff;
    @include xm {
      font-size: 28px;
      line-height: 32px;
    }
    @include m-small {
      font-size: 22px;
      line-height: 25px;
    }
    @include xxs {
      font-size: 20px;
      line-height: 23px;
    }
  }
  span {
    margin-top: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    margin-top: 10px;
    display: block;
    @include xxs {
      font-size: 12px;
      margin-top: 5px;
    }
  }
}

.c-title {
  span {
    font-weight: 500;
    font-size: 25px;
    line-height: 29px;
    color: #232323;
    @include m-small {
      font-size: 18px;
      line-height: 25px;
    }
    @include xxs {
      font-size: 16px;
      line-height: 18px;
    }
  }
}

.c-logo {
  width: 154px;
  height: 66px;
  min-width: 154px;
  @include xxs {
    width: 110px;
    height: 50px;
    min-width: none;
    img {
      width: 110px;
      height: 50px;
    }
  }
  img {
    width: 154px;
    height: 66px;
  }
}

hr.hr {
  width: 300vw;
  margin: 0;
  height: 1px;
  background-color: #005999;
  position: relative;
  left: -200.9%;
  margin-block-end: 0;
  margin-block-start: 0;
  border-width: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

ul.c-contacts {
  li {
    margin-bottom: 20px;
  }
  .c-df-10,
  .c-df-5 {
    align-items: center;
  }
}

.c-search-input {
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 10px;
  justify-content: space-between;
  padding: 13px 15px;
  height: 45px;
  input {
    border: unset;
    margin: 0;
    height: unset;
    padding: unset;
    color: #232323;
    font-size: 16px;
    box-shadow: unset;
    &:focus {
      box-shadow: unset;
      border: unset;
    }
  }
  i {
    width: 24px;
    height: 24px;
  }
}

.c-blue-btn {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #005999;
  border-radius: 10px;
  @include m-small {
    font-size: 16px;
  }
  @include xxs {
    font-size: 14px;
    line-height: 16px;
  }
}

.c-hover {
  transition: all 0.3s ease-in-out;
  &:hover {
    transition: all 0.3s ease-in-out;
    -webkit-filter: drop-shadow(0px 0px 25px rgba(0, 0, 0, 0.25));
    filter: drop-shadow(0px 0px 25px rgba(13, 26, 99, 0.534));
    // color: rgba(0, 0, 0, 0.25) !important;
    color: white;
  }
}

.c-text-overflow-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.c-block {
  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(189, 189, 189, 0.1);
  border-radius: 20px;
  padding: 40px;
  @include xm {
    padding: 25px;
  }
  @include m-small {
    padding: 20px;
  }
  @include xxs {
    padding: 15px;
  }
}

.c-page-block {
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 20px;
  padding: 50px;
  @include xxm {
    padding: 20px;
  }
  @include m-640 {
    border-radius: 10px;
  }
  @include xxs {
    padding: 15px;
  }
}

.c-df-column {
  display: flex;
  flex-direction: column;
}
.c-df-sb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.c-df-5 {
  display: flex;
  gap: 5px;
}
.c-df-10 {
  display: flex;
  gap: 10px;
}
.mq-column {
  @include m-small {
    flex-direction: column;
  }
}
.c-df-20 {
  display: flex;
  align-items: center;
  gap: 20px;
  @include m-small {
    gap: 10px;
  }
}

.c-tb-18 {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #232323;
  @include l {
    font-size: 14px;
    line-height: 16px;
  }
  @include xxxs {
    font-size: 12px;
    line-height: 14px;
  }
}

.c-tb-18-500 {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #232323;
  @include m-small {
    font-size: 14px;
    line-height: 16px;
  }
  @include xxxs {
    font-size: 12px;
    line-height: 14px;
  }
}

.c-tb-18-300 {
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: #232323;
  @include m-small {
    font-size: 14px;
    line-height: 16px;
  }
  @include xxxs {
    font-size: 12px;
    line-height: 14px;
  }
}

.c-tb-20 {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #232323;
  @include xm {
    font-size: 18px;
    font-weight: 20px;
  }
  @include xxs {
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
  }
  @include xxxs {
    font-size: 14px;
    line-height: 16px;
  }
}

.c-tb-28 {
  font-weight: 400;
  font-size: 28px;
  line-height: 32px;
  color: #232323;
  @include xm {
    font-size: 24px;
    line-height: 30px;
  }
  @include xxs {
    font-size: 18px;
    line-height: 24px;
  }
  @include kura {
    font-size: 16px;
    line-height: 18px;
  }
}

.c-tb-14 {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #232323;
  @include xxxs {
    font-size: 12px;
  }
}

.c-tb-16 {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #232323;
  @include m-small {
    font-size: 14px;
  }
  @include xxxs {
    font-size: 12px;
  }
}
.c-tw-16 {
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: white;
  @include m-small {
    font-size: 14px;
  }
  @include xxxs {
    font-size: 12px;
    line-height: 14px;
  }
}
a.c-tw-16 {
  &:hover {
    color: white;
  }
}

.c-tblue-16 {
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #74add6;
  @include m-small {
    font-size: 14px;
  }
  @include xxxs {
    font-size: 12px;
    line-height: 14px;
  }
}

.c-tblue-14 {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #74add6;
  @include xxs {
    font-size: 12px;
    line-height: 14px;
  }
}

.c-tg-16 {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #9DA7BB;
  @include xxxs {
    font-size: 10px;
    line-height: 11px;
  }
}

.c-tg-14 {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #9a9a9a;
  @include xxxs {
    font-size: 10px;
    line-height: 11px;
  }
}

.c-tg-14-300 {
  font-weight: 300;
  font-size: 14px;
  line-height: 140%;
  color: #615e5e;
  @include xxxs {
    font-size: 10px;
    line-height: 11px;
  }
}

.c-text {
  font-weight: 300;
  font-size: 18px;
  line-height: 140%;
  color: #232323;
  @include l {
    font-size: 14px;
    line-height: 16px;
  }
  @include xxxs {
    font-size: 12px;
    line-height: 14px;
  }
}

.c-banner {
  height: 192px;
  min-height: 120px;
  width: 100%;
  background: url("../img/bg.png") no-repeat center/cover;
  @include l {
    height: 180px;
  }
  @include xxs {
    height: 150px;
  }
  @include kura {
    height: 120px;
  }
}

.l-breadcrumbs {
  margin-bottom: 30px;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  @include xxs {
    margin-bottom: 20px;
  }
  ul {
    display: flex;
    gap: 8px;
    align-items: center;
    white-space: nowrap;

    li {
      &:last-child {
        a {
          color: white;
        }
      }
      i.dot {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #dadada;
        display: block;
      }
      a {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #dadada;
        display: block;
        @include xxs {
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
  }
}

.c-banner-content {
  padding: 30px 0 0 0;
  @include xxs {
    padding: 20px 0 0 0;
  }
}

.c-mt--76 {
  margin-top: -76px;
  &::-webkit-scrollbar {
    display: none;
  }
  @include m-670 {
    margin-top: 20px;
  }
}

.input-list {
  background: #fff;
  border: 1px solid #dadada;
  border-radius: 10px;
  width: 100%;
  position: relative;
  height: 45px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: flex;
  padding: 10px 15px;
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  @include xxs {
    height: 41px;
    padding: 10px;
  }
  &:focus {
    border: 1px solid #dadada;
    box-shadow: 0px 0px 5px 0px rgb(26 83 181 / 35%);
  }
  &:hover {
    box-shadow: 0px 0px 5px 0px rgb(26 83 181 / 35%);
  }
  i.arrow-blue {
    position: absolute;
    right: 10px;
    top: 48%;
  }
  input {
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    color: #232323;
    &::placeholder {
      font-size: 18px;
      @include m-small {
        font-size: 14px;
      }
      @include xxxs {
        font-size: 12px;
      }
    }
    @include m-small {
      font-size: 14px;
    }
    @include xxxs {
      font-size: 12px;
    }
  }
}

ul.c-list__content {
  li {
    border-bottom: 1px solid #dadada;
    padding: 10px 0;
    &:first-child {
      padding-top: unset;
    }
    a {
      display: flex;
      align-items: center;
      gap: 20px;
      width: fit-content;
      @include m-small {
        gap: 10px;
      }
      i {
        order: -1;
      }
    }
  }
}

ul.c-text-list {
  li {
    margin-bottom: 20px;
    margin-left: 30px;
    position: relative;
    @include xxs {
      margin-left: 15px;
    }
    &::before {
      content: "";
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #005999;
      display: block;
      position: absolute;
      left: -30px;
      top: 25%;
      @include xxs {
        left: -15px;
        top: 16%;
        width: 8px;
        height: 8px;
      }
    }
    &:first-child {
      margin-left: unset;
      &::before {
        display: none;
      }
    }
    &:last-child {
      margin-bottom: unset;
    }
  }
}

.static-form {
  .l-item {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    &:last-child {
      margin-bottom: unset;
    }
  }
}

ul.l-dropdown-list {
  .accordion-item {
    &.is-active {
      & > a {
        border: 1px solid #005999;
        i.arrow-blue {
          transform: rotate(180deg);
        }
      }
      .title {
        i.arrow-blue {
          transform: rotate(180deg);
        }
      }
    }
  }
  .accordion-item > a {
    position: relative;
    i.arrow-blue {
      position: absolute;
      right: 15px;
      top: 44%;
      transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
  }
}

.c-margin-l {
  margin-left: 30px;
  @include xxs {
    margin-left: 15px;
  }
}

.l-accordion-child__content {
  li {
    margin-bottom: 15px !important;
    &:last-child {
      margin-bottom: unset !important;
    }
    &.is-active {
      .title-child {
        color: #005999;
        i.arrow-blue {
          transform: rotate(180deg);
        }
      }
    }
    .title-child {
      background: #fff;
      border: 1px solid #dadada;
      border-radius: 20px;
      padding: 19px 40px 19px 20px;
      position: relative;
      width: 100%;
      display: block;
      i.arrow-blue {
        position: absolute;
        right: 15px;
        top: 44%;
        transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
      }
    }
  }
}

.c-search-block {
  display: flex;
  gap: 30px;
  align-items: center;
  @include xxs-big {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  .c-search-input {
    background: #ffffff;
    border: 1px solid #dadada;
    border-radius: 10px;
    width: 100%;
    i.search {
      margin-right: 20px;
    }
  }
}

.c-search-btn {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 44px;
  background: #005999;
  border-radius: 10px;
  color: white;
  @include xxs-big {
    width: 100%;
  }
  &:hover {
    color: white;
    -webkit-filter: drop-shadow(0px 0px 25px rgba(0, 0, 0, 0.25));
    filter: drop-shadow(0px 0px 25px rgba(13, 26, 99, 0.534));
  }
}

.zakup-list {
  border: 1px solid #dadada;
  overflow-x: scroll;
  @include m-670 {
    overflow: visible;
  }
  li {
    // border-bottom: 1px solid #dadada;
    display: flex;
    flex-direction: column;
    @include m-670 {
      display: grid;
      grid-template-columns: 115px 1fr;
      @include xxs {
        grid-template-columns: 90px 1fr;
      }
    }
    &:not(:first-child) {
      .top {
        display: none;
        @include m-670 {
          display: grid;
          grid-template-columns: 1fr;
        }
      }
    }
  }
  .item {
    align-items: center;
    // height: 72px;
    min-height: 72px;
    @include m-670 {
      grid-template-rows: 70px 1fr 72px;
    }
    P {
      @include xxxs {
        &:nth-child(2) {
          padding: 5px;
          @include xxs {
            line-height: 14px;
          }
        }
      }
    }
    a {
      box-shadow: 1px -1px 0 0 #dadada;
      display: flex;
      align-items: center;
      // padding: 16px 0;
      height: 100%;
      @include xm {
        padding: 20px 0;
      }
      @include m-670 {
        box-shadow: unset;
        border-bottom: 1px solid #dadada;
        height: 69px;
        padding: 0;
      }
      i {
        margin: 0 auto;
      }
    }
  }
  .top {
    @include m-670 {
      grid-template-rows: 71px 1fr 71px;
    }
    p {
      color: white;
      background-color: #005999;
      border-right: 1px solid #dadada ;
      @include m-670 {
        background-color: unset;
        color: #232323;
      }
    }
  }
  .top,
  .item {
    display: grid;
    grid-template-columns: 130px 635px 102px;
    @include m-670 {
      grid-template-columns: 1fr;
    }
    p {
      margin: -1px 0px -1px 0px;
      box-shadow: 1px -1px 0 0 #dadada;
      padding: 27px 10px;
      height: 100%;
      @include m-670 {
        padding: 14px;
        box-shadow: unset;
        border-bottom: 1px solid #dadada;
      }
    }
  }
}

.zakup-list-4-column {
  border: 1px solid #dadada;
  overflow-x: scroll;
  @include m-670 {
    overflow: visible;
  }
  li {
    // border-bottom: 1px solid #dadada;
    display: flex;
    flex-direction: column;
    @include m-670 {
      display: grid;
      grid-template-columns: 115px 1fr;
      @include xxs {
        grid-template-columns: 90px 1fr;
      }
    }
    &:not(:first-child) {
      .top {
        display: none;
        @include m-670 {
          display: grid;
          grid-template-columns: 1fr;
        }
      }
    }
  }
  .item {
    align-items: center;
    height: 72px;
    P {
      &:nth-child(2) {
        padding: 8px 10px !important;
        height: 71px;
        @include m-670 {
          overflow: auto;
        }
      }
      @include xxxs {
        &:nth-child(2) {
          padding: 5px;
          @include xxs {
            line-height: 14px;
          }
        }
      }
    }
    a {
      box-shadow: 1px -1px 0 0 #dadada;
      padding: 16px 0;
      @include xm {
        padding: 20px 0;
      }
      @include m-670 {
        box-shadow: unset;
        border-bottom: 1px solid #dadada;
        height: 70px;
      }
      i {
        margin: 0 auto;
      }
    }
  }
  .top {
    p {
      color: white;
      background-color: #005999;
      @include m-670 {
        background-color: unset;
        color: #232323;
      }
    }
  }
  .top,
  .item {
    display: grid;
    grid-template-columns: 95px 498px 130px 145px;
    @include m-670 {
      grid-template-columns: 1fr;
    }
    p {
      margin: -1px 0px -1px 0px;
      box-shadow: 1px -1px 0 0 #dadada;
      padding: 26px 10px;
      @include m-670 {
        height: 71px;
        padding: 14px;
        box-shadow: unset;
        border-bottom: 1px solid #dadada;
      }
    }
  }
}

.datepick-zakup {
  @include m-small {
    flex-direction: column;
    align-items: flex-start;
    .js-select {
      width: 100%;
    }
  }
  .js-select {
    width: 200px;
  }
}
