footer.footer {
  background: #005999;
  margin-top: 20px;
  hr.hr {
    background: #2775AD;
  }
  .l-footer-lower {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include xxs {
      flex-direction: column;
      gap: 15px;
    }
    .right {
      a {
        display: flex;
        align-items: center;
        gap: 14px;

      }
    }
  }
}

.l-footer-content {
  padding: 40px 0 58px 0;
  display: grid;
  grid-template-columns: 260px 1fr 310px;
  justify-content: space-between;
  gap: 15px;
  @include xl {
    grid-template-columns: 310px 1fr;
  }
  @include xm {
    grid-template-columns: 1fr;
    gap: 20px;
  }
  @include xxs {
    padding: 20px 0  25px 0;
  }
}
.m-logo {
  display: none;
  @include xm {
    display: block;
  }
}

.f-column-1 {
  .c-logo {
    margin-bottom: 20px;
    @include xm {
      display: none;
    }
  }
}

.f-column-2 {
  display: flex;
  justify-content: center;
  gap: 34px;
  @include m-900 {
    gap: 15px;
  }
  @include xm {
    grid-row-start: 2;
    justify-content: flex-start;
  }
  @include xxs {
    flex-direction: column;
  }
}

.f-column-3 {
  @include xl {
    grid-row-start: 2;
  }
  @include xm {
    grid-row-start: 3;
    max-width: 50%;
  }
  @include xxs-big {
    max-width: 100%;
  }
}

ul.l-footer-list  {
  display: flex;
  flex-direction: column;

  li {
    margin-bottom: 30px;
    @include xxs {
      margin-bottom: 20px;
    }
    &:last-child {
      margin-bottom: unset;
    }
  }

}