*,
*:before,
*:after {
  box-sizing: border-box;
}
// =========================================================================
// eto xyuna pri klike na ssulku ybrat fon sinego sveta nado dobavit na element
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
//=================================================
html,
body,
div,
span,
object,
iframe,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
code,
em,
img,
small,
strike,
strong,
sub,
sup,
tt,
b,
u,
i,
ol,
ul,
li,
fieldset,
form,
label,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
main,
canvas,
embed,
footer,
header,
nav,
section,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
  text-decoration: none;
}

footer,
header,
nav,
section,
main {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  -webkit-appearance: none;
  border-radius: 0;
}
/* ==========================================================================
Foundation (reset + base)
============================================================================== */
/* Reset
    -------------------------------------------------------------------- */

.menu .button,
.menu a {
  padding: 0;
}
.dropdown.menu > li.is-dropdown-submenu-parent > a {
  padding: 0;
}

.dropdown.menu>li.is-active>a {
  color: white;
}

ins,
mark {
  color: #000;
}
a,
hr {
  padding: 0;
}

a:focus {
  color: var(--color-text-white);
}
#page-top a,
.l-panel-item > a,
a,
ins {
  text-decoration: none;
}
abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: 0 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
li,
nav ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none;
}
a {
  margin: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: 0 0;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
a:hover {
  color: unset;
}
ins {
  background-color: #00f;
}
mark {
  background-color: #ff9;
  font-style: italic;
  font-weight: 700;
}
del {
  text-decoration: line-through;
}
abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
input,
select {
  vertical-align: middle;
}
.cf:after {
  content: "";
  clear: both;
  display: block;
}
/*clearfix*/
.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  font-size: 0;
  clear: both;
  visibility: hidden;
}
/* Hides from IE Mac */
* html .clearfix {
  height: 1%;
}
hr {
  max-width: unset;
}
/* End Hack */

/* Base
-------------------------------------------------------------------- */
img {
  max-width: 100%;
  height: auto;
  width: auto;
  vertical-align: bottom;
}

.is-dropdown-submenu {
  z-index: 100;
}

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: none;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
  display: none;
}

.accordion {
  background: unset;
}
.accordion-content {
  padding: 0;
  border: unset;
  background: unset;
  margin: 0;
}
.accordion-item:first-child>:first-child {
  border-radius: 10px;
}
ol ol, ol ul, ul ol, ul ul {
  margin: 0;
}

[type="color"],
[type="date"],
[type="datetime-local"],
[type="datetime"],
[type="email"],
[type="month"],
[type="number"],
[type="password"],
[type="search"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"],
[type="week"],
textarea {
  border: unset;
  height: unset;
  margin: 0;
  padding: 0;
  box-shadow: unset;
  border-radius: unset;
  &:focus {
    border: unset;
    box-shadow: unset;
  }
  &::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #7c7c7c;
  }
}
