.l-main-banner {
  height: 500px;
  position: relative;
  @include m-small {
    height: 400px;
  }
  @include xxs {
    height: 320px;
  }
  @include kura {
    height: 280px;
  }
  .swiper-slide {
    display: flex;
    align-items: center;
  }
}

.l-main-banner__item {
  h3 {
    font-weight: 700;
    font-size: 50px;
    line-height: 57px;
    color: #ffffff;
    margin-bottom: 25px;
    @include l {
      font-size: 28px;
      line-height: 32px;
      margin-bottom: 20px;
    }
    @include xxs {
      font-size: 20px;
      line-height: 140%;
      margin-bottom: 10px;
    }
  }
  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #ffffff;
    margin-bottom: 25px;
    @include l {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 20px;
    }
    @include xxs {
      font-size: 12px;
      line-height: 140%;
    }
  }
  a {
    max-width: min-content;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #005999;
    display: block;
    padding: 10px 25px;
    text-align: center;
    background: #ffffff;
    border-radius: 8px;
    @include xxs {
      font-size: 14px;
    }
    &:hover {
      color: #232323;
    }
  }
}

.l-main-banner__pagination {
  position: absolute;
  left: 70px !important;
  bottom: 120px !important;
  z-index: 1;
  @include l {
    left: 55px !important;
    bottom: 80px !important;
  }
  @include xm {
    left: 40px !important;
  }
  @include m-small {
    bottom: 16px !important;
    left: 16px !important;
  }
  span {
    background-color: white;
    width: 12px;
    height: 12px;
  }
}
