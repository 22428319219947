
section.aside-section {
  .l-content {
    display: grid;
    grid-template-columns: 310px 1fr;
    gap: 20px;
    @include xl-1300 {
      grid-template-columns: 200px 1fr;
    }
    @include m-670 {
      grid-template-columns: 1fr;
      gap: 5px;
    }
  }
}
aside.l-aside {
  ul {
    li {
      margin-bottom: 15px;
      @include m-small {
        margin-bottom: 10px;
      }
      a {
        background: #ffffff;
        border: 1px solid #dadada;
        border-radius: 10px;
        display: block;
        width: 100%;
        padding: 13px 15px;

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &.active {
          border: 1px solid #005999;
          color: #005999;
        }
      }
    }
  }
}

.l-timeline {
  position: relative;
  margin-left: 40px;
  @include xm {
    margin-left: unset;
    &::after {
      display: none;
    }
  }
  .l-item {
    padding-bottom: 30px;
    img {
      margin: 30px 0;
      border-radius: 20px;
      object-fit: fill;
      width: 100%;
      @include m-small {
        margin: 15px 0;
        border-radius: 10px;
      }
    }
    p {
      font-weight: 300;
      font-size: 18px;
      line-height: 140%;
      color: #232323;
      @include xxm {
        font-size: 16px;
        line-height: 20px;
      }
      @include xxs {
        font-size: 12px;
        line-height: 17px;
      }
    }
    &:first-child {
      i.dot-timeline {
        background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='12' fill='%23005999'/%3E%3Ccircle cx='11.9998' cy='12.0001' r='9.23077' fill='white'/%3E%3Ccircle cx='12.0002' cy='12' r='4.61538' fill='%23005999'/%3E%3C/svg%3E%0A")
          no-repeat center/cover;
        width: 24px;
        height: 24px;
        display: block;
      }
    }
    &:last-child {
      padding-bottom: unset;
      i.dot-timeline {
        background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='12' fill='%23005999'/%3E%3Ccircle cx='11.9998' cy='12.0001' r='9.23077' fill='white'/%3E%3Ccircle cx='12.0002' cy='12' r='4.61538' fill='%23005999'/%3E%3C/svg%3E%0A")
          no-repeat center/cover;
        width: 24px;
        height: 24px;
        display: block;
      }
      .l-item-text {
        position: relative;
        z-index: 3;
        padding-left: 50px;
        left: -50px;
        background: white;
        box-shadow: 4px 8px 0 10px white;
        @include m-670 {
          padding-left: unset;
          left: unset;
          box-shadow: unset;
        }
      }
    }
    .l-item-title {
      display: flex;
      align-items: center;
      position: relative;
      gap: 15px;
      i {
        position: absolute;
        left: -42px;
        z-index: 2;
        @include xm {
          position: unset;
        }
      }
      span {
        font-weight: 400;
        font-size: 20px;
        line-height: 140%;
        color: #005999;
        @include m-small {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
  &::after {
    content: "";
    position: absolute;
    border-right: 1px dashed #005999;
    height: 100%;
    left: -31px;
    top: 10px;
    z-index: 1;
  }
}
