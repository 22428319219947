.l-news-content {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  @include xl {
    flex-direction: column;
  }
}

.l-news-grid {
  display: grid;
  grid-template-columns: 1fr 310px;
  gap: 20px;
  @include xl {
    width: 100%;
  }
  @include xm {
    grid-template-columns: 1fr;
  }
  .l-item {
    img {
      border-radius: 20px;
      width: 100%;
      max-height: 528px;
      height: 100vh;
      object-fit: fill;
      @include xm {
        max-height: 400px;
      }
      @include m-small {
        max-height: 320px;
      }
      @include xxs {
        max-height: 230px;
      }
      @include kura {
        max-height: 180px;
      }
    }
    figcaption {
      margin-top: 10px;
      gap: 20px;
      @include xxs {
        gap: 10px;
      }
    }
    &:not(:nth-child(1)) {
      img {
        max-height: 210px;
        @include xm {
          max-height: 400px;
        }
        @include m-small {
          max-height: 320px;
        }
        @include xxs {
          max-height: 230px;
        }
        @include kura {
          max-height: 180px;
        }
      }
    }
    &:nth-child(1) {
      grid-row-start: span 2;
      @include xm {
        grid-row-start: unset;
      }
    }
  }
}

.l-news-list {
  max-width: 420px;
  @include xl {
    width: 100%;
    max-width: 100%;
  }
  ul {
    background: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 0px 16px rgba(189, 189, 189, 0.1);
    padding: 40px 30px;
    max-height: 525px;
    @include m-small {
      padding: 15px;
    }
    li {
      margin-bottom: 15px;
      border-bottom: 1px solid #dadada;
      padding-bottom: 5px;
      &:last-child {
        margin-bottom: unset;
      }
      a {
        span.c-text-overflow-2 {
          margin-bottom: 15px;
          @include xxs {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  .c-blue-btn {
    margin-top: 30px;
    @include xl {
      max-width: 420px;
    }
  }
}
