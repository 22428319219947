i.search {
  background: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M26.9998 27L21.8055 21.7964M24.684 14.8421C24.684 17.4524 23.6471 19.9558 21.8013 21.8015C19.9556 23.6473 17.4522 24.6842 14.8419 24.6842C12.2316 24.6842 9.72826 23.6473 7.8825 21.8015C6.03675 19.9558 4.99982 17.4524 4.99982 14.8421C4.99982 12.2318 6.03675 9.72844 7.8825 7.88269C9.72826 6.03693 12.2316 5 14.8419 5C17.4522 5 19.9556 6.03693 21.8013 7.88269C23.6471 9.72844 24.684 12.2318 24.684 14.8421Z' stroke='%23005999' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A")
    no-repeat center/cover;
  width: 32px;
  height: 32px;
  display: block;
}

i.eye {
  background: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.7734 16C20.7734 18.64 18.64 20.7734 16 20.7734C13.36 20.7734 11.2267 18.64 11.2267 16C11.2267 13.36 13.36 11.2267 16 11.2267C18.64 11.2267 20.7734 13.36 20.7734 16Z' stroke='%23005999' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M16 27.0266C20.7067 27.0266 25.0934 24.2533 28.1467 19.4533C29.3467 17.5733 29.3467 14.4133 28.1467 12.5333C25.0934 7.73329 20.7067 4.95996 16 4.95996C11.2934 4.95996 6.9067 7.73329 3.85337 12.5333C2.65337 14.4133 2.65337 17.5733 3.85337 19.4533C6.9067 24.2533 11.2934 27.0266 16 27.0266Z' stroke='%23005999' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
    no-repeat center/cover;
  width: 32px;
  height: 32px;
  display: block;
}

i.lk {
  background: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1569_3798)'%3E%3Cpath d='M16.0003 31.0669C24.3214 31.0669 31.0669 24.3214 31.0669 16.0003C31.0669 7.67917 24.3214 0.933594 16.0003 0.933594C7.67917 0.933594 0.933594 7.67917 0.933594 16.0003C0.933594 24.3214 7.67917 31.0669 16.0003 31.0669Z' stroke='white' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M16.0026 19.8672C11.3359 19.8672 7.33594 22.5339 5.33594 26.5339C8.0026 29.3339 11.8693 30.9339 16.0026 30.9339C20.1359 30.9339 24.0026 29.2005 26.6693 26.5339C24.6693 22.6672 20.6693 19.8672 16.0026 19.8672Z' stroke='white' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M16.0031 16.4008C18.6541 16.4008 20.8031 14.2517 20.8031 11.6008C20.8031 8.94981 18.6541 6.80078 16.0031 6.80078C13.3522 6.80078 11.2031 8.94981 11.2031 11.6008C11.2031 14.2517 13.3522 16.4008 16.0031 16.4008Z' stroke='white' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1569_3798'%3E%3Crect width='32' height='32' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A")
    no-repeat center/cover;
  width: 32px;
  height: 32px;
  display: block;
}

i.arrow {
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

i.arrow-blue {
  background: url("data:image/svg+xml,%3Csvg width='16' height='8' viewBox='0 0 16 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 1L8 7L1 0.999999' stroke='%23005999' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
    no-repeat center/cover;
  width: 21px;
  height: 10px;
  display: block;
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  &.active {
    transform: rotate(180deg);
  }
}

i.close {
  background: url("data:image/svg+xml, %3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 3L21 21' stroke='%23005999' stroke-width='2' stroke-linecap='round'/%3E%3Cpath d='M21 3L3 21' stroke='%23005999' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A")
    no-repeat center/cover;
  width: 24px;
  height: 24px;
  display: block;
}

i.platforma {
  background: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0H19L9.66667 9.15789L0 18V0Z' fill='%2374ADD6'/%3E%3Cpath d='M20 20H1L10.3333 10.8421L20 2V20Z' fill='%2374ADD6'/%3E%3C/svg%3E%0A")
    no-repeat center/cover;
  width: 24px;
  height: 24px;
  display: block;
}

i.point {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.75 9.75C18.75 7.95979 18.0388 6.2429 16.773 4.97703C15.5071 3.71116 13.7902 3 12 3C10.2098 3 8.4929 3.71116 7.22703 4.97703C5.96116 6.2429 5.25 7.95979 5.25 9.75C5.25 12.519 7.4655 16.128 12 20.451C16.5345 16.128 18.75 12.519 18.75 9.75ZM12 22.5C6.4995 17.5005 3.75 13.2495 3.75 9.75C3.75 7.56196 4.61919 5.46354 6.16637 3.91637C7.71354 2.36919 9.81196 1.5 12 1.5C14.188 1.5 16.2865 2.36919 17.8336 3.91637C19.3808 5.46354 20.25 7.56196 20.25 9.75C20.25 13.2495 17.5005 17.5005 12 22.5Z' fill='%2374ADD6'/%3E%3Cpath d='M12 12C12.5967 12 13.169 11.7629 13.591 11.341C14.0129 10.919 14.25 10.3467 14.25 9.75C14.25 9.15326 14.0129 8.58097 13.591 8.15901C13.169 7.73705 12.5967 7.5 12 7.5C11.4033 7.5 10.831 7.73705 10.409 8.15901C9.98705 8.58097 9.75 9.15326 9.75 9.75C9.75 10.3467 9.98705 10.919 10.409 11.341C10.831 11.7629 11.4033 12 12 12ZM12 13.5C11.0054 13.5 10.0516 13.1049 9.34835 12.4017C8.64509 11.6984 8.25 10.7446 8.25 9.75C8.25 8.75544 8.64509 7.80161 9.34835 7.09835C10.0516 6.39509 11.0054 6 12 6C12.9946 6 13.9484 6.39509 14.6517 7.09835C15.3549 7.80161 15.75 8.75544 15.75 9.75C15.75 10.7446 15.3549 11.6984 14.6517 12.4017C13.9484 13.1049 12.9946 13.5 12 13.5Z' fill='%2374ADD6'/%3E%3C/svg%3E%0A")
    no-repeat center/cover;
  width: 24px;
  height: 24px;
  display: block;
}

i.mail {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.25 4H18.75C19.5801 3.99995 20.3788 4.31755 20.9822 4.88767C21.5856 5.45779 21.948 6.23719 21.995 7.066L22 7.25V16.75C22.0001 17.5801 21.6824 18.3788 21.1123 18.9822C20.5422 19.5856 19.7628 19.948 18.934 19.995L18.75 20H5.25C4.41986 20.0001 3.62117 19.6824 3.01777 19.1123C2.41437 18.5422 2.052 17.7628 2.005 16.934L2 16.75V7.25C1.99995 6.41986 2.31755 5.62117 2.88767 5.01777C3.45779 4.41437 4.23719 4.052 5.066 4.005L5.25 4H18.75H5.25ZM20.5 9.373L12.35 13.663C12.258 13.7116 12.1568 13.7405 12.053 13.7479C11.9492 13.7553 11.845 13.7411 11.747 13.706L11.651 13.664L3.5 9.374V16.75C3.50002 17.1892 3.66517 17.6123 3.96268 17.9354C4.26019 18.2585 4.6683 18.4579 5.106 18.494L5.25 18.5H18.75C19.1893 18.5 19.6126 18.3347 19.9357 18.037C20.2588 17.7392 20.4581 17.3309 20.494 16.893L20.5 16.75V9.373ZM18.75 5.5H5.25C4.81081 5.50002 4.38768 5.66517 4.06461 5.96268C3.74154 6.26019 3.54214 6.6683 3.506 7.106L3.5 7.25V7.679L12 12.152L20.5 7.678V7.25C20.5 6.81065 20.3347 6.38739 20.037 6.06429C19.7392 5.74119 19.3309 5.5419 18.893 5.506L18.75 5.5Z' fill='%2374ADD6'/%3E%3C/svg%3E%0A")
    no-repeat center/cover;
  width: 24px;
  height: 24px;
  display: block;
}

i.phone {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 9.75H16.5C16.4994 9.15345 16.2622 8.5815 15.8403 8.15967C15.4185 7.73784 14.8466 7.5006 14.25 7.5V6C15.2442 6.00119 16.1973 6.39666 16.9003 7.09967C17.6033 7.80267 17.9988 8.7558 18 9.75Z' fill='%2374ADD6'/%3E%3Cpath d='M20.9988 9.75H19.4988C19.4972 8.3581 18.9436 7.02367 17.9594 6.03944C16.9751 5.05522 15.6407 4.50159 14.2488 4.5V3C16.0384 3.00199 17.7541 3.71378 19.0196 4.97922C20.285 6.24466 20.9968 7.9604 20.9988 9.75ZM19.4988 21.75H19.3713C4.63381 20.9025 2.54131 8.4675 2.24881 4.6725C2.20309 4.07756 2.39557 3.48883 2.7839 3.03579C3.17224 2.58276 3.72462 2.30253 4.31956 2.25675C4.37906 2.25225 4.43881 2.25 4.49881 2.25H8.45131C8.75172 2.24971 9.04531 2.33963 9.29403 2.50812C9.54275 2.67661 9.73515 2.9159 9.84631 3.195L10.9863 6C11.0961 6.27266 11.1233 6.57156 11.0646 6.85957C11.006 7.14758 10.864 7.412 10.6563 7.62L9.05881 9.2325C9.30733 10.651 9.98609 11.9587 11.0031 12.9784C12.02 13.998 13.326 14.6802 14.7438 14.9325L16.3713 13.32C16.5824 13.1146 16.8493 12.9759 17.1388 12.9213C17.4282 12.8666 17.7273 12.8983 17.9988 13.0125L20.8263 14.145C21.1012 14.2597 21.3357 14.4536 21.5 14.702C21.6643 14.9504 21.7509 15.2422 21.7488 15.54V19.5C21.7488 20.0967 21.5118 20.669 21.0898 21.091C20.6678 21.5129 20.0955 21.75 19.4988 21.75ZM4.49881 3.75C4.40031 3.7497 4.30273 3.76881 4.21162 3.80623C4.12051 3.84365 4.03767 3.89864 3.96782 3.96808C3.89796 4.03751 3.84247 4.12003 3.80451 4.21091C3.76654 4.30179 3.74685 4.39926 3.74656 4.49775C3.74656 4.51875 3.74731 4.5395 3.74881 4.56C4.09381 9 6.30631 19.5 19.4538 20.25C19.6523 20.2619 19.8474 20.1945 19.9962 20.0625C20.145 19.9306 20.2353 19.745 20.2473 19.5465L20.2488 19.5V15.54L17.4213 14.4075L15.2688 16.545L14.9088 16.5C8.38381 15.6825 7.49881 9.1575 7.49881 9.09L7.45381 8.73L9.58381 6.5775L8.45881 3.75H4.49881Z' fill='%2374ADD6'/%3E%3C/svg%3E%0A")
    no-repeat center/cover;
  width: 24px;
  height: 24px;
  display: block;
}
i.phone-big {
  background: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M43.94 36.66C43.94 37.38 43.78 38.12 43.44 38.84C43.1 39.56 42.66 40.24 42.08 40.88C41.1 41.96 40.02 42.74 38.8 43.24C37.6 43.74 36.3 44 34.9 44C32.86 44 30.68 43.52 28.38 42.54C26.08 41.56 23.78 40.24 21.5 38.58C19.2 36.9 17.02 35.04 14.94 32.98C12.88 30.9 11.02 28.72 9.36 26.44C7.72 24.16 6.4 21.88 5.44 19.62C4.48 17.34 4 15.16 4 13.08C4 11.72 4.24 10.42 4.72 9.22C5.2 8 5.96 6.88 7.02 5.88C8.3 4.62 9.7 4 11.18 4C11.74 4 12.3 4.12 12.8 4.36C13.32 4.6 13.78 4.96 14.14 5.48L18.78 12.02C19.14 12.52 19.4 12.98 19.58 13.42C19.76 13.84 19.86 14.26 19.86 14.64C19.86 15.12 19.72 15.6 19.44 16.06C19.18 16.52 18.8 17 18.32 17.48L16.8 19.06C16.58 19.28 16.48 19.54 16.48 19.86C16.48 20.02 16.5 20.16 16.54 20.32C16.6 20.48 16.66 20.6 16.7 20.72C17.06 21.38 17.68 22.24 18.56 23.28C19.46 24.32 20.42 25.38 21.46 26.44C22.54 27.5 23.58 28.48 24.64 29.38C25.68 30.26 26.54 30.86 27.22 31.22C27.32 31.26 27.44 31.32 27.58 31.38C27.74 31.44 27.9 31.46 28.08 31.46C28.42 31.46 28.68 31.34 28.9 31.12L30.42 29.62C30.92 29.12 31.4 28.74 31.86 28.5C32.32 28.22 32.78 28.08 33.28 28.08C33.66 28.08 34.06 28.16 34.5 28.34C34.94 28.52 35.4 28.78 35.9 29.12L42.52 33.82C43.04 34.18 43.4 34.6 43.62 35.1C43.82 35.6 43.94 36.1 43.94 36.66Z' stroke='%23005999' stroke-width='1.5' stroke-miterlimit='10'/%3E%3Cpath d='M37 18C37 16.8 36.06 14.96 34.66 13.46C33.38 12.08 31.68 11 30 11' stroke='%23005999' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M44 18C44 10.26 37.74 4 30 4' stroke='%23005999' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
    no-repeat center/cover;
  width: 48px;
  height: 48px;
  min-width: 48px;
  display: block;
  @include xm {
    width: 32px;
    min-width: 32px;
    height: 32px;
  }
}

i.arrow__prev {
  background: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d_1606_5)'%3E%3Ccircle cx='20' cy='20' r='16' fill='white'/%3E%3C/g%3E%3Cpath d='M23 28L15 20L23 12' stroke='%23005999' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cdefs%3E%3Cfilter id='filter0_d_1606_5' x='0' y='0' width='40' height='40' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset/%3E%3CfeGaussianBlur stdDeviation='2'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1606_5'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_1606_5' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A")
    no-repeat center/cover;
  width: 32px;
  height: 32px;
  display: block;
}
i.arrow__next {
  transform: rotate(180deg);
  background: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d_1606_5)'%3E%3Ccircle cx='20' cy='20' r='16' fill='white'/%3E%3C/g%3E%3Cpath d='M23 28L15 20L23 12' stroke='%23005999' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cdefs%3E%3Cfilter id='filter0_d_1606_5' x='0' y='0' width='40' height='40' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset/%3E%3CfeGaussianBlur stdDeviation='2'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1606_5'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_1606_5' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A")
    no-repeat center/cover;
  width: 32px;
  height: 32px;
  display: block;
}

i.dot-1 {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='12' fill='%23005999'/%3E%3Ccircle cx='11.9998' cy='12.0001' r='9.23077' fill='white'/%3E%3Ccircle cx='12.0002' cy='12' r='4.61538' fill='%23005999'/%3E%3C/svg%3E%0A")
    no-repeat center/cover;
  width: 24px;
  height: 24px;
  display: block;
}

i.dot-timeline {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='12' fill='%23005999'/%3E%3Cellipse cx='12' cy='11.9881' rx='6.54545' ry='6.53885' fill='white'/%3E%3C/svg%3E%0A")
    no-repeat center/cover;
  width: 26px;
  height: 26px;
  display: block;
}

i.download {
  background: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 18.3334V28.3334L18.3333 25' stroke='%23005999' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14.9993 28.3333L11.666 25' stroke='%23005999' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M36.6673 16.6667V25C36.6673 33.3334 33.334 36.6667 25.0007 36.6667H15.0007C6.66732 36.6667 3.33398 33.3334 3.33398 25V15C3.33398 6.66671 6.66732 3.33337 15.0007 3.33337H23.334' stroke='%23005999' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M36.6673 16.6667H30.0007C25.0007 16.6667 23.334 15 23.334 10V3.33337L36.6673 16.6667Z' stroke='%23005999' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
    no-repeat center/cover;
  width: 40px;
  height: 40px;
  min-width: 40px;
  display: block;
  @include xm {
    width: 32px;
    min-width: 32px;
    height: 32px;
  }
}

i.note {
  background: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 4V10' stroke='%23005999' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M32 4V10' stroke='%23005999' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M42 17V34C42 40 39 44 32 44H16C9 44 6 40 6 34V17C6 11 9 7 16 7H32C39 7 42 11 42 17Z' stroke='%23005999' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M16 22H32' stroke='%23005999' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M16 32H24' stroke='%23005999' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
    no-repeat center/cover;
  width: 48px;
  height: 48px;
  min-width: 48px;
  display: block;
  @include xm {
    width: 32px;
    min-width: 32px;
    height: 32px;
  }
}

i.note-2 {
  background: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M43.3204 20.88L41.3604 29.24C39.6804 36.46 36.3604 39.38 30.1204 38.78C29.1204 38.7 28.0404 38.52 26.8804 38.24L23.5204 37.44C15.1804 35.46 12.6004 31.34 14.5604 22.98L16.5204 14.6C16.9204 12.9 17.4004 11.42 18.0004 10.2C20.3404 5.36002 24.3204 4.06002 31.0004 5.64002L34.3404 6.42002C42.7204 8.38002 45.2804 12.52 43.3204 20.88Z' stroke='%23005999' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M30.1197 38.7802C28.8797 39.6202 27.3197 40.3202 25.4197 40.9402L22.2597 41.9802C14.3197 44.5402 10.1397 42.4002 7.55971 34.4602L4.99971 26.5602C2.43971 18.6202 4.55971 14.4202 12.4997 11.8602L15.6597 10.8202C16.4797 10.5602 17.2597 10.3402 17.9997 10.2002C17.3997 11.4202 16.9197 12.9002 16.5197 14.6002L14.5597 22.9802C12.5997 31.3402 15.1797 35.4602 23.5197 37.4402L26.8797 38.2402C28.0397 38.5202 29.1197 38.7002 30.1197 38.7802Z' stroke='%23005999' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M25.2803 17.0596L34.9803 19.5196' stroke='%23005999' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M23.3203 24.7998L29.1203 26.2798' stroke='%23005999' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
    no-repeat center/cover;
  width: 48px;
  height: 48px;
  min-width: 48px;
  display: block;
  @include xm {
    width: 32px;
    min-width: 32px;
    height: 32px;
  }
}

i.edit {
  background: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 4H18C8 4 4 8 4 18V30C4 40 8 44 18 44H30C40 44 44 40 44 30V26' stroke='%23005999' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M32.0798 6.04002L16.3198 21.8C15.7198 22.4 15.1198 23.58 14.9998 24.44L14.1398 30.46C13.8198 32.64 15.3598 34.16 17.5398 33.86L23.5598 33C24.3998 32.88 25.5798 32.28 26.1998 31.68L41.9598 15.92C44.6798 13.2 45.9598 10.04 41.9598 6.04002C37.9598 2.04002 34.7998 3.32002 32.0798 6.04002Z' stroke='%23005999' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M29.8203 8.2998C31.1603 13.0798 34.9003 16.8198 39.7003 18.1798' stroke='%23005999' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
    no-repeat center/cover;
  width: 48px;
  height: 48px;
  min-width: 48px;
  display: block;
  @include xm {
    width: 32px;
    min-width: 32px;
    height: 32px;
  }
}

i.empty {
  width: 48px;
  height: 48px;
  min-width: 48px;
  display: block;
  @include xm {
    width: 32px;
    min-width: 32px;
    height: 32px;
  }
}

i.lock {
  background: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 20V16C12 9.38 14 4 24 4C34 4 36 9.38 36 16V20' stroke='%23005999' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M24 37C26.7614 37 29 34.7614 29 32C29 29.2386 26.7614 27 24 27C21.2386 27 19 29.2386 19 32C19 34.7614 21.2386 37 24 37Z' stroke='%23005999' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M34 44H14C6 44 4 42 4 34V30C4 22 6 20 14 20H34C42 20 44 22 44 30V34C44 42 42 44 34 44Z' stroke='%23005999' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
    no-repeat center/cover;
  width: 48px;
  height: 48px;
  min-width: 48px;
  display: block;
  @include xm {
    width: 32px;
    min-width: 32px;
    height: 32px;
  }
}

i.user {
  background: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M36.2797 43.2395C34.5197 43.7595 32.4397 43.9995 29.9997 43.9995H17.9997C15.5597 43.9995 13.4797 43.7595 11.7197 43.2395C12.1597 38.0395 17.4997 33.9395 23.9997 33.9395C30.4997 33.9395 35.8397 38.0395 36.2797 43.2395Z' stroke='%23005999' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M30 4H18C8 4 4 8 4 18V30C4 37.56 6.28 41.7 11.72 43.24C12.16 38.04 17.5 33.9399 24 33.9399C30.5 33.9399 35.84 38.04 36.28 43.24C41.72 41.7 44 37.56 44 30V18C44 8 40 4 30 4ZM24 28.34C20.04 28.34 16.84 25.12 16.84 21.16C16.84 17.2 20.04 14 24 14C27.96 14 31.16 17.2 31.16 21.16C31.16 25.12 27.96 28.34 24 28.34Z' stroke='%23005999' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M31.1599 21.16C31.1599 25.12 27.9598 28.34 23.9998 28.34C20.0398 28.34 16.8398 25.12 16.8398 21.16C16.8398 17.2 20.0398 14 23.9998 14C27.9598 14 31.1599 17.2 31.1599 21.16Z' stroke='%23005999' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
    no-repeat center/cover;
  width: 48px;
  height: 48px;
  min-width: 48px;
  display: block;
  @include xm {
    width: 32px;
    min-width: 32px;
    height: 32px;
  }
}

i.key {
  background: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M39.5803 29.8596C35.4603 33.9596 29.5603 35.2196 24.3803 33.5996L14.9603 42.9996C14.2803 43.6996 12.9403 44.1196 11.9803 43.9796L7.62029 43.3797C6.18029 43.1797 4.84029 41.8197 4.62029 40.3797L4.02029 36.0196C3.88029 35.0596 4.34029 33.7196 5.00029 33.0396L14.4003 23.6396C12.8003 18.4396 14.0403 12.5396 18.1603 8.43965C24.0603 2.53965 33.6403 2.53965 39.5603 8.43965C45.4803 14.3396 45.4803 23.9596 39.5803 29.8596Z' stroke='%23005999' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M13.7803 34.9805L18.3803 39.5805' stroke='%23005999' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M29 22C30.6569 22 32 20.6569 32 19C32 17.3431 30.6569 16 29 16C27.3431 16 26 17.3431 26 19C26 20.6569 27.3431 22 29 22Z' stroke='%23005999' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
    no-repeat center/cover;
  width: 48px;
  height: 48px;
  min-width: 48px;
  display: block;
  @include xm {
    width: 32px;
    min-width: 32px;
    height: 32px;
  }
}

i.sms {
  background: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M34 41H14C8 41 4 38 4 31V17C4 10 8 7 14 7H34C40 7 44 10 44 17V31C44 38 40 41 34 41Z' stroke='%23005999' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M34 18L27.74 23C25.68 24.64 22.3 24.64 20.24 23L14 18' stroke='%23005999' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
    no-repeat center/cover;
  width: 48px;
  height: 48px;
  min-width: 48px;
  display: block;
  @include xm {
    width: 32px;
    min-width: 32px;
    height: 32px;
  }
}

i.lk-2 {
  background: url("data:image/svg+xml,%3Csvg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M31.9997 5.33203C25.013 5.33203 19.333 11.012 19.333 17.9987C19.333 24.852 24.693 30.3987 31.6797 30.6387C31.893 30.612 32.1063 30.612 32.2663 30.6387C32.3197 30.6387 32.3463 30.6387 32.3997 30.6387C32.4263 30.6387 32.4263 30.6387 32.453 30.6387C39.2797 30.3987 44.6397 24.852 44.6663 17.9987C44.6663 11.012 38.9863 5.33203 31.9997 5.33203Z' fill='%23005999'/%3E%3Cpath d='M45.5466 37.7317C38.1066 32.7717 25.9732 32.7717 18.4799 37.7317C15.0932 39.9984 13.2266 43.0651 13.2266 46.3451C13.2266 49.6251 15.0932 52.6651 18.4532 54.9051C22.1866 57.4117 27.0932 58.6651 31.9999 58.6651C36.9066 58.6651 41.8132 57.4117 45.5466 54.9051C48.9066 52.6384 50.7732 49.5984 50.7732 46.2917C50.7466 43.0117 48.9066 39.9717 45.5466 37.7317Z' fill='%23005999'/%3E%3C/svg%3E%0A")
    no-repeat center/cover;
  width: 64px;
  height: 64px;
  min-width: 64px;
  display: block;
  @include m-640 {
    min-width: 32px;
    width: 32px;
    height: 32px;
  }
}

i.lk-1 {
  background: url("data:image/svg+xml,%3Csvg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23.9997 5.33203C17.013 5.33203 11.333 11.012 11.333 17.9987C11.333 24.852 16.693 30.3987 23.6797 30.6387C23.893 30.612 24.1063 30.612 24.2663 30.6387C24.3197 30.6387 24.3463 30.6387 24.3997 30.6387C24.4263 30.6387 24.4263 30.6387 24.453 30.6387C31.2797 30.3987 36.6397 24.852 36.6663 17.9987C36.6663 11.012 30.9863 5.33203 23.9997 5.33203Z' fill='%23005999'/%3E%3Cpath d='M37.5466 37.7317C30.1066 32.7717 17.9732 32.7717 10.4799 37.7317C7.09323 39.9984 5.22656 43.0651 5.22656 46.3451C5.22656 49.6251 7.09323 52.6651 10.4532 54.9051C14.1866 57.4117 19.0932 58.6651 23.9999 58.6651C28.9066 58.6651 33.8132 57.4117 37.5466 54.9051C40.9066 52.6384 42.7732 49.5984 42.7732 46.2917C42.7466 43.0117 40.9066 39.9717 37.5466 37.7317Z' fill='%23005999'/%3E%3Cpath d='M53.307 19.5723C53.7336 24.7456 50.0536 29.279 44.9603 29.8923C44.9336 29.8923 44.9336 29.8923 44.907 29.8923H44.827C44.667 29.8923 44.507 29.8923 44.3736 29.9456C41.787 30.079 39.4136 29.2523 37.627 27.7323C40.3736 25.279 41.947 21.599 41.627 17.599C41.4403 15.439 40.6936 13.4656 39.5736 11.7856C40.587 11.279 41.7603 10.959 42.9603 10.8523C48.187 10.399 52.8536 14.2923 53.307 19.5723Z' fill='%23005999'/%3E%3Cpath d='M58.64 44.2398C58.4266 46.8264 56.7733 49.0664 54 50.5864C51.3333 52.0531 47.9733 52.7464 44.64 52.6664C46.56 50.9331 47.68 48.7731 47.8933 46.4798C48.16 43.1731 46.5866 39.9998 43.44 37.4664C41.6533 36.0531 39.5733 34.9331 37.3066 34.1064C43.2 32.3998 50.6133 33.5464 55.1733 37.2264C57.6266 39.1998 58.88 41.6798 58.64 44.2398Z' fill='%23005999'/%3E%3C/svg%3E%0A")
    no-repeat center/cover;
  width: 64px;
  height: 64px;
  min-width: 64px;
  display: block;
  @include m-640 {
    min-width: 32px;
    width: 32px;
    height: 32px;
  }
}
