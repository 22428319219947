ul.l-contacts-top {
  li {
    padding: 20px 0;
    border-bottom: 1px solid #dadada;
    &:first-child {
      padding-top: unset;
    }
  }
  article {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.l-contacts-accordion {
  margin-top: 30px;
  ul.accordion {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .accordion-item {
    background: #ffffff;
    box-shadow: 0px 0px 16px rgba(189, 189, 189, 0.1);
    border-radius: 20px;
    padding: 0 50px 0 50px;
    .acc-title {
      padding: 28px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      i {
        width: 20px;
        height: 10px;
        transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
      }
    }
    &.is-active {
      background: #ffffff;
      border: 1px solid #dadada;
      border-radius: 20px;
      padding: 0 50px 28px 50px;
      .acc-title {
        display: flex;
        width: 100%;
        color: #005999;
        border-bottom: 1px solid #DADADA;
        i {
          transform: rotate(180deg);
        }
      }
    }
  }
  .accordion-item:first-child>:first-child {
    border-radius: unset;
  }
}

.l-contacts-accordion__item-yur {
  .l-item  {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .l-yur__item {
    display: grid;
    grid-template-columns: 30% 30% 30%;
  }
  li {
    padding: 30px 0;
    border-bottom: 1px solid #dadada;
  }
}