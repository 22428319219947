header {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  position: relative;
}

.l-header-top {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .l-left {
    .l-list {
      display: flex;
      @include xm {
        display: none;
      }
      li {
        a {
          transition: var(--transition);
          padding: 16px 15px;
          white-space: nowrap;
          &:hover {
            background: #005999c5;

            color: white;
          }
          &.active {
            color: white;
            background: #005999;
          }
        }
      }
    }
  }
  .l-right {
    ul {
      display: flex;
      align-items: center;
      .b-header-phone {
        @include xxl {
          flex-direction: column;
          gap: 1px !important;
        }
      }
      li {
        display: flex;
        gap: 15px;
        align-items: center;
        border-right: 2px solid #005999;
        border-radius: 2px;
        padding: 0px 15px;
        height: 30px;
        @include xl {
          &:nth-child(1),
          &:nth-child(2) {
            display: none;
          }
        }
        &:last-child {
          border-right: unset;
          padding: 0 0 0 15px;
          @include xl {
            border-left: 2px solid #005999;
          }
          @include xxs {
            border-left: unset;
          }
        }
      }
    }
  }
}

.m-dropdown {
  display: none;
  ul.dropdown {
    height: 50px;
  }
  @include xm {
    display: block;
    width: 205px;
    background-color: #005999;
    li.first {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 17px 16px;
      a {
        color: white;
      }
      .second {
        width: 205px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        a {
          color: #232323;
        }
      }
    }
  }
  @include xxs {
    position: relative;
    left: -16px;
  }
}

.b-header-eye,
.b-header-phone {
  display: flex;
  gap: 10px;
  align-items: center;
}

.l-header-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  gap: 20px;
  @include xxs {
    height: 67px;
  }
}

nav.c-header-nav {
  max-width: 745px;
  width: 100%;
  position: relative;
  @include xl {
    max-width: 100%;
  }
  @include m-640 {
    border-bottom: 1px solid #D9D9D9;
    padding-bottom: 20px;
  }
  ul.dropdown {
    li.is-active {
      a {
        color: #005999;
      }
      ul.submenu {
        display: flex;
      }
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        background: #005999;
        border-radius: 3px 3px 0px 0px;
        height: 4px;
        left: 0;
        bottom: 0;
        opacity: 1;
        transition: all 0.5s ease;
        @include xl {
          display: none;
        }
      }
    }
    ul.submenu {
      display: none;
      gap: 10px;
      flex-direction: column;
      padding: 10px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      top: 112% !important;
      li {
        &:hover {
          &::after {
            display: none;
          }
        }
      }
      a {
        padding: 10px !important;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #000000 !important;
        &:hover {
          color: #005999 !important;
          &::after {
            display: none;
          }
        }
      }
    }
  }
  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    @include xl {
      align-items: flex-start;
      flex-direction: column;
    }
    li {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        background: #005999;
        border-radius: 3px 3px 0px 0px;
        height: 4px;
        left: 0;
        bottom: -10px;
        opacity: 0;
        transition: all 0.5s ease;
        @include xl {
          display: none;
        }
      }
      &.active {
        a {
          background: unset;
          color:#005999 ;
        }
        &::after {
          bottom: 0;
          opacity: 1;
        }
      }
      &:hover {
        a {
          color: #005999;
        }
        &::after {
          bottom: 0;
          opacity: 1;
        }
      }
      @include xl {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: unset;
        }
      }
      @include xm {
        margin-bottom: 10px;
      }

      a {
        padding: 32px 0 !important;
        transition: all 0.5s ease;
        position: relative;
        color: #232323;
        @include xl {
          padding: 10px 0 !important;
        }
        &.active {
          color: #005999;
          &::after {
            bottom: 0;
            opacity: 1;
            @include xl {
              display: none;
            }
          }
        }
      }
    }
  }
}

.l-lk-btn {
  @include xl {
    display: none;
  }
}

.l-lk-btn,
.m-lk-btn {
  a {
    background: #005999;
    border: 2px solid #005999;
    border-radius: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 8px 15px;
    span {
      color: white;
      white-space: nowrap;
    }
  }
}

.b-header-nav {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  
  .l-bottom {
    display: none;
  }
  @include xl {
    overflow: scroll;
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100%;
    max-height: 580px;
    padding: 65px 60px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    z-index: 100;
    transform: translateX(200%);
    transition: all 0.45s linear;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    @include m-640 {
      padding: 30px;
      height: 100%;
      max-height: 500px;
      gap: 20px 0;
    }
    @include xxs {
      padding: 30px 16px;
      height: 417px;
    }
    &.active-menu {
      transform: translateX(0);
    }
    .l-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .m-lk-btn {
        @include xxs {
          display: none;
        }
      }
      @include m-950 {
        flex-direction: column;
        gap: 15px;
        align-items: flex-start;
      }
    }
  }
}

.m-close-btn {
  display: none;
  cursor: pointer;
  @include xl {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.m-burger-menu {
  display: none;
  z-index: 10;
  @include xl {
    display: block;
    position: relative;
    right: -5px;
  }
  .menu {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 0;
  }
  .line {
    fill: none;
    stroke: #005999;
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .line1 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }
  .line2 {
    stroke-dasharray: 60 60;
    stroke-width: 6;
  }
  .line3 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }
  .opened .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
  }
  .opened .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 6;
  }
  .opened .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
  }
}
