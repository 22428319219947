.l-news-page {
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 20px;
  padding: 50px;
  @include l {
    padding: 40px 29px;
  }
  @include m-small {
    padding: unset;
    background: 0 0;
    border: unset;
    border-radius: unset;
  }
}

.datepick {
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 10px;
  width: 150px;
  position: relative;
  height: 45px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  i.arrow-blue {
    position: absolute;
    right: 10px;
    top: 41%;
  }
  &::value {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #9da7bb;
    @include m-small {
      font-size: 14px;
    }
  }
}

.l-seacrh-bar {
  .search-form {
    display: flex;
    gap: 30px;
    .js-select {
      width: 200px;
      @include m-small {
        width: 100%;
      }
    }
    @include xxm {
      flex-direction: column;
    }
    @include m-small {
      background: #ffffff;
      border: 1px solid #dadada;
      border-radius: 10px;
      padding: 15px;
      .l-datepick {
        flex-direction: column;
        align-items: flex-start !important;
        div {
          flex-direction: column;
          width: 100%;
        }
      }
    }
    span {
      white-space: nowrap;
    }
  }
}

.l-news-items__grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  @include xm {
    grid-template-columns: 1fr;
  }
}

.l-news__item {
  max-width: 590px;
  img {
    height: 300px;
    width: 100%;
    border-radius: 10px 10px 0 0;
    @include xxs {
      height: 250px;
      width: 100%;
    }
    @include xxxs {
      height: 200px;
    }
  }
  .l-item-text {
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    border: 1px solid #dadada;
    background: white;
    border-radius: 0 0 10px 10px;
    flex-direction: column;
    gap: 10px;
    span {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    a {
      @include xxs {
        font-size: 12px;
      }
    }
    p {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    @include xxs {
      padding: 10px;
    }
  }
}

.l-news-page__detail {
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 20px;
  padding: 50px;
  @include xm {
    padding: 20px;
  }
  @include xxs {
    padding: 15px;
    border-radius: 10px;
  }
  .c-df-20 {
    align-items: flex-start;
  }
}

.l-news-detail {
  display: grid;
  grid-template-columns: 480px 1fr;
  @include xl {
    grid-template-columns: 1fr;
  }
  img {
    border-radius: 10px;
    height: 425px;
    width: 100%;
    @include xl {
      height: 328px;
    }
    @include m-small {
      height: 230px;
    }
    @include xxxs {
      height: 200px;
    }
  }
  p {
    font-weight: 300;
    font-size: 18px;
    line-height: 140%;
    color: #232323;
    margin-bottom: 30px;
    @include m-small {
      font-size: 16px;
      margin-bottom: 15px;
    }
    @include xxs {
      font-size: 12px;
      line-height: 140%;
      margin-bottom: 10px;
    }
  }
}
