.l-accordion-content {
  margin: 15px 0 0 15px;
  a {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    white-space: nowrap;
  }
}

ul.l-info-page {
  margin-left: 50px;
  @include m-670 {
    margin-left: 40px;
  }
  .l-info-page__title {
    position: relative;
    display: flex;
    align-items: flex-start;
    align-items: center;
    margin-bottom: 10px;
    a {
      position: absolute;
      left: -50px;
      @include m-670 {
        left: -40px;
      }
    }
    span {
      font-weight: 500;
    }
  }
  li {
    margin-bottom: 40px;
    @include xxs {
      margin-bottom: 20px;
    }
    p {
      font-weight: 300;
      font-size: 18px;
      line-height: 140%;
      color: #232323;
      @include l {
        font-size: 14px;
        line-height: 16px;
      }
      @include xxxs {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
}

ul.l-dropdown-list {
  ul.c-text-list {
    li {
      margin-bottom: 10px;
    }
  }
  .accordion-item:first-child > :first-child {
    border-radius: 20px;
  }
  li {
    margin-bottom: 30px;
    @include m-small {
      margin-bottom: 15px;
    }
    &:last-child {
      margin-bottom: unset;
    }
    .content {
      border: unset !important;
      padding: 30px;
      @include m-small {
        padding: 10px;
      }
    }
    a.title {
      background: #ffffff;
      border: 1px solid #dadada;
      border-radius: 20px;
      padding: 19px 40px 19px 20px;
      position: relative;
      width: 100%;
      display: block;
      @include m-small {
        padding: 15px 30px 15px 10px;
      }
    }
  }
}

.l-new-table {
  display: grid;
  grid-template-columns: 1fr;
  @include m-670 {
    overflow: unset;
    padding-bottom: 50px;
  }
  .table-form__table {
    // margin-bottom: 20px;
  }

  ul {
    // overflow-x: scroll;
    li {
      overflow-x: scroll;
      @include m-670 {
        overflow-x: unset;

      }
      &:first-child {
        @include m-670 {
          display: none;
        }
      }
    }
    .top {
      display: grid;
      grid-template-columns: 246px 102px 128px 210px 120px 64px;
      background-color: #005999;
      text-align: center;
      height: 50px;
      p {
        color: white;
        align-self: center;
        height: 100%;
        display: flex;
        align-items: center;
        width: 100%;
        border: 1px solid #dadada;
        justify-content: center;
      }
      @include m-670 {
        grid-template-columns: 1fr;
      }
    }
    .item {
      display: grid;
      grid-template-columns: 246px 102px 128px 210px 120px 64px;
      @include m-670 {  
        margin-bottom: 20px;
      }
      .item__title {
        display: none;
        @include m-670 {
          display: block;
          p {

            background-color: #005999;
            width: 100%;
            color: white;
          }
        }
      }
      .item__text {
        height: 100%;
        @include m-670 {  
          height: unset;
        }
      }
      @include m-670 {
        grid-template-columns: 1fr;
      }
      p {
        padding: 15px;
        border: 1px solid #dadada;
        height: 100%;
      }
    }
  }
}

.l-table {
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}

.table-form__search {
  @include xm {
    flex-direction: column;
    button {
      width: 100%;
    }
  }
  button {
    background: #005999;
    border-radius: 10px;
    color: #ffffff !important;
    padding: 12px 44px;
    align-self: flex-end;
  }
}

.p-otkluchenie-grid {
  grid-template-columns: 246px 102px 128px 210px 120px 64px;
  &:not(:first-child) {
    .title {
      display: none;
      @include m-670 {
        display: flex;
        // grid-template-columns: 1fr;
      }
    }
  }
  @include m-670 {
    grid-template-columns: 1fr;
  }
}

.table-form__table {
  display: grid;

  text-align: center;
  // overflow-x: scroll;
  @include m-670 {
    grid-template-columns: 1fr;
    .block-name {
      height: auto;
    }
  }
  .title {
    background-color: #005999;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include xxs {
      height: 42px;
    }
  }
  .block-name,
  .lower {
    padding: 15px 24px;
    border: 1px solid #dadada;
  }
  .block-name {
    // height: 190px;
  }
  .lower {
    height: 50px;
  }
  h5 {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: center;

    color: #ffffff;
    @include xxs {
      font-size: 14px;
    }
  }
  span {
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;

    color: #232323;
    @include xxs {
      font-size: 12px;
    }
  }
}

.c-grid-table {
  display: grid;
  grid-template-columns: 65% 35%;
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 20px;
  overflow: hidden;
  &.other {
    grid-template-columns: 55% 45%;
    .top,
    .item {
      justify-content: flex-start;
      span {
        @include kura {
          font-size: 12px;
        }
      }
    }
  }
  .block {
    margin: 0px 0px -1px 0px;
    box-shadow: 1px -1px 0 0 #dadada;
  }
  .top {
    height: 63px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    span {
      font-weight: 500;
      font-size: 20px;
      line-height: 18px;
      color: #005999;
      @include xl {
        font-size: 16px;
      }
      @include m-small {
        font-size: 14px;
      }
      @include xxs {
        line-height: 17px;
      }
    }
  }
  .item {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 63px;
    margin: -1px 0px -1px 0px;
    box-shadow: 1px -1px 0 0 #dadada;
    span {
      font-weight: 300;
      font-size: 18px;
      line-height: 21px;
      color: #232323;
      @include xl {
        font-size: 14px;
        line-height: 16px;
      }
      @include xxxs {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
}

ul.table-list-mq {
  overflow-x: scroll;
  border: 1px solid #dadada;
  @include m-670 {
    overflow: visible;
    border: unset;
  }
  &::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #005999;
    border: 1px solid #555555;
  }
  li {
    display: flex;
    flex-direction: column;
    @include m-670 {
      display: grid;
      grid-template-columns: 150px 1fr;
      margin-bottom: 20px;
      border: 1px solid #dadada;
    }
    @include m-small {
      grid-template-columns: 124px 1fr;
    }
    &:not(:first-child) {
      .top {
        display: none;
        @include m-670 {
          display: grid;
        }
      }
    }
    .top {
      display: grid;
      grid-template-columns: 153px 266px 227px 220px;
      // padding: 0 16px;
      align-items: center;
      height: 50px;
      @include m-670 {
        grid-template-columns: 1fr;
        height: auto;
        p {
          margin: -1px 0px -1px 0px;
          box-shadow: 1px -1px 0 0 #dadada;
        }
      }
      p {
        padding: 16px;

        background-color: #005999;
        color: white;
      }
    }
    .item {
      height: 72px;
      display: grid;
      grid-template-columns: 153px 266px 227px 220px;
      align-items: center;
      @include m-670 {
        grid-template-columns: 1fr;
        height: auto;
        p {
          padding: 16px;
        }
      }

      p {
        padding: 27px 16px;
        margin: -1px 0px -1px 0px;
        box-shadow: 1px -1px 0 0 #dadada;
        @include m-small {
          padding: 0 5px;
          margin: unset;
          box-shadow: unset;
        }
      }
    }
  }
}
