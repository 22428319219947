.js-select {
  display: block;
  position: relative;
  &.active {
    .js-select__input::after {
      transform: rotate(180deg);
    }
    .js-select__list {
      display: block;
      max-height: 250px;
      overflow-y: auto;
    }
  }
}

.js-select__input {
  display: flex;
  align-items: center;
  height: 45px;
  position: relative;
  cursor: pointer;
  width: 100%;
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 10px;
  padding: 10px 15px;
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #9da7bb;
  @include m-small {
    font-size: 14px;
    line-height: 16px;
    padding: 14px 10px;
  }
  @include xxxs {
    font-size: 12px;
    line-height: 14px;
  }
  &::after {
    content: "";
    width: 21px;
    height: 10px;
    display: block;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='8' viewBox='0 0 16 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 1L8 7L1 0.999999' stroke='%23005999' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    right: 15px;
    top: 41%;
    transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  &:hover {
    box-shadow: 0px 0px 5px 0px rgb(26 83 181 / 35%);
  }
}

.js-select__list {
  display: none;
  cursor: pointer;
  width: 100%;
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 10px;
  padding: 9px 0;
  box-sizing: border-box;
  max-height: 0;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 55px;
  z-index: 2;
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  span {
    display: block;
    width: 100%;
    padding: 10px 20px;
    cursor: pointer;
    transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
    &:hover {
      background-color: #e5f3fe;
    }
  }
}
