
.l-grid-leadership {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  @include xl {
    grid-template-columns: 1fr;
  }
  .l-item {
    max-width: 425px;
    img {
      height: 362px;
      border-radius: 10px 10px 0 0;
      @include xxs {
        height: 250px;
        width: 100%;
      }
      @include xxxs {
        height: 200px;
      }
    }
    .l-item-text {
      padding: 20px;
      display: flex;
      justify-content: flex-start;
      border: 1px solid #DADADA;
      background: white;
      border-radius: 0 0 10px 10px ;
      flex-direction: column;
      gap: 10px;
      @include xxs {
        padding: 10px;
      }
    }
  }
}